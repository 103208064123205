:root {
  --black: #262626;
  --blend: difference;
  --blue: #25418F;
  --gray: #e6e6e6;
  --main-font: 1rem;
  --header-font: 1.2rem;
  --footnote: 0.85rem;
  --footnote2: 0.75rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: white;
  font-size: 16px;
}

@font-face {
  font-family: "Helveesti";
  src: url(../../public/assets/fonts/helveesti-regular.woff);
}

@font-face {
  font-family: "Helveesti";
  src: url(../../public/assets/fonts/helveesti-regular-italic.woff);
  font-style: italic;
}

.slick-next {
  right: 0.25rem !important;
  mix-blend-mode: difference;
}

.slick-prev {
  left: 0.25rem !important;
  z-index: 9999;
  mix-blend-mode: difference;
}

.navbar-mobile a {
  display: none;
}

p {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  line-height: 1.4rem;
  color: var(--black);
  text-align: left;
  margin-bottom: 1rem;
}

h2 {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  line-height: 1.3rem;
  color: var(--black);
  background: yellow;
  margin-bottom: 0.5rem;
  display: inline-block;
  text-align: left;
}

.copy-footer {
  display: flex;
  background: white;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid var(--black);
}

.footnote-italic {
  font-family: "Helveesti";
  font-size: inherit;
  color: var(--black);
  font-style: italic;
}

.home {
  display: flex;
  width: 100vw;
  margin-top: 47px;
  padding: 5rem;
  height: calc(100dvh - 76px);
  position: fixed;
  z-index: 1000;
  align-items: center;
  justify-content: center;

}


.nht {
  font-size: var(--footnote);
}

.home-logo {
  display: none;
}

.nht a {
  background: yellow;
  font-size: var(--footnote);
}

.homeish {
  /* background: white; */
  padding: 1rem 1rem 0 1rem;
  /* border: 1px solid var(--black); */
  width: 50%;
}

.homeish img {
  width: 100%;
  transition: all ease-in-out 250ms;
}

.homeish img:hover {
  cursor: pointer;
  scale: 1.05;
}

.homeish p {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  line-height: 1.3rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.homeish p a {
  color: var(--black);
  text-decoration: underline;
  transition: all ease-in-out 250ms;
}

.homeish p a:hover {
  background: var(--black);
  color: white;
  text-decoration: none;
}


.lb {
  display: none;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bug {
  position: relative;
  width: 100%;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin-top: 0.5rem;

}

.bug img {
  width: 33%;
  background: yellow;
}

/* .carousel-image:hover {
  cursor: pointer;
} */

.carousel-image:focus {
  outline: none;
  border: none;
}

.carousel-image:focus-visible {
  outline: none;
}

.contact-left {
  padding-right: 24px;
}

.contact-left p {
  text-align: left !important;
}

.contact-right {
  text-align: left !important;
}

.copy {
  padding: 1.5rem 0rem 1.5rem 1.5rem;
  overflow-y: scroll;
  height: calc(100dvh - 46px);
  opacity: 0;
  transition: opacity ease-in-out 250ms;
  margin: 0;

}

.copy.loaded {
  opacity: 1;
}

.board-container {
  display: grid;
  column-gap: 1rem;
  row-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
}

.board-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.board-seat {
  display: block;
}

.board-member {
  font-family: "Helveesti";
  font-size: var(--main-font);
  display: flex;
  align-items: center;
  justify-content: left;
}

.board-title {
  font-family: "Helveesti";
  font-size: var(--main-font);
  font-style: italic;
}

.board-container>*:last-child {
  margin-bottom: 0rem;
}

.board-container p {
  font-style: italic !important;
  font-weight: 200 !important;

}

.section {
  position: fixed;
  display: grid;
  width: 100vw;
  grid-template-columns: 4fr 2fr;
  gap: 1.5rem;
  margin-top: 50px;
  padding-right: 1.5rem;
  height: calc(100dvh - 46px);
  z-index: 1;
}

.section h2 {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  line-height: 1.3rem;
  color: var(--black);
  background: yellow;
  margin-bottom: 0.5rem;
  display: inline-block;
  text-align: left;
}

.section h3 {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: var(--black);
  text-align: left;
  display: block;
}

.section h4 {
  display: flex;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: var(--black);
  text-align: left;
  /* display: block; */
  background-color: var(--black);
  color: white;
  align-items: center;
  justify-content: center;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border: none;

}

.loading img {
  width: 100px !important;
  border: none !important;
  /* animation: loading 250ms alternate-reverse infinite; */
}

@keyframes loading {
  0% {
    scale: 1;
  }

  100% {
    scale: 1.2;
  }

}


.copy::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.copy::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.copy img {
  width: 100%;
  border: 1px solid var(--black);
}

.copy .img-credit {
  font-size: var(--footnote);
  line-height: 0.75rem;
  text-align: left;
  font-style: italic;
  margin-bottom: 0.5rem;
}


.copy .section-heading {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--black);
  background: white;
}

.copy .img-heading {
  padding: 0.5rem 0.5rem 0.24rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--black);
  background: white;
}

.copy .img-heading img {
  opacity: 0.9;
  transition: opacity ease-in-out 250ms;
}

.copy .img-heading img:hover {
  opacity: 1;
  cursor: pointer;
}

.calendar-section {
  padding: 1rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid var(--black);
  background: white;
  max-height: 1000px;
  /* Initial small height */
  overflow: hidden;
  /* Hide overflowing content */
  transition: max-height 2500ms ease-in;
}

.calendar-section.show-content {
  max-height: 10000px;
  /* This should be larger than you expect the content to be */
}

.event {
  margin-bottom: 0rem;
}

.calendar-event {
  padding: 1rem 1rem 0rem 1rem;
  border: 1px solid var(--black);
  margin-bottom: 1rem;
  background: #fdfdfd;
  opacity: 1;
  transition: opacity ease-in-out 500ms;
}

.calendar-event.loaded {
  opacity: 1;
}

.event h2 {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  line-height: 1.3rem;
  color: var(--black);
  background: yellow;
  margin-bottom: 0.5rem;
  text-align: justify;
  display: inline-block;
  text-align: left;
}

.event h2 a {
  color: black;
  display: inline;
  background: none;
}

.event-title {
  background: yellow;
  display: inline !important;
}


.event-info {
  margin: 0.5rem 0rem;
}

.event-link {
  padding: 0.25rem;
  border: 1px solid var(--black);
  background: var(--gray);
  color: var(--black) !important;
  text-align: center;
  transition: all ease-in-out 200ms;
  margin-top: 0.5rem;
}

.event-link:hover {
  background: var(--black);
  color: white !important;
  cursor: pointer;
}

.project-button {
  padding: 0.1rem 1rem;
  border: 1px solid var(--black);
  border-radius: 0;
  background: white;
  text-align: center;
  transition: all ease-in-out 200ms;
  width: auto;
  display: inline !important;
}

.project-button:hover {
  background: var(--black);
  color: white;
  cursor: pointer;
}

.event a {
  text-decoration: none;
  color: var(--black);
}

.event img {
  width: 100%;
  background: white;
  /* margin-bottom: 4rem; */
  border: 1px solid var(--black);
  opacity: 0.8;
  transition: opacity ease 250ms;
}

.event img:hover {
  opacity: 1;
}

/* .event span {
  vertical-align: super;
  font-size: var(--footnote) !important;
  line-height: 0;
  transition: all ease-in-out 250ms;
  display: inline-block !important;
}

.event span:hover {
  background: yellow !important;
  color: var(--black);
} */

.event h1 {
  font-size: var(--header-font);
}

.event h1 a {
  color: var(--black);
  text-decoration: underline;
  transition: color ease 250ms;
}

.event h1 a:hover {
  text-decoration: underline;
  color: var(--blue);

}

.footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  bottom: 0;
  padding: 0.5rem 0rem 0rem;
  overflow: hidden;
  white-space: nowrap;
  background: white;
  border-bottom: 1px solid var(--black);
  z-index: 50;
}

.footer p {
  font-family: "Helveesti";
  font-size: 0.75rem !important;
  font-style: italic !important;
  line-height: 0.5rem !important;
  color: var(--black);
  display: inline-block;
  white-space: nowrap;
  padding-bottom: 0.5rem;
  animation: scrollText 30s linear infinite;
  margin-bottom: 0rem !important;
}

.footer p a {
  font-size: 0.75rem !important;
  font-style: italic !important;
  text-decoration: none;
  transition: all ease-in-out 100ms;
}


.footer p a:active {
  background: yellow;
  color: var(--black);
}

.footer p a:visited {
  color: var(--black);
}

.mobile-footer {
  display: none;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.footnotes-container {
  position: relative;
  margin: 0;
  overflow-y: scroll;
  padding: 1.5rem 0rem;
  opacity: 0;
  transition: all ease-in-out 500ms;
  display: block;
}

.footnotes-container.loaded {
  opacity: 1;
}

.footnotes-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.footnotes-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.footnotes {
  position: relative;
  height: fit-content;
  padding: 0;
  background: white;
  border-top: 1px solid var(--black);
  border-left: 1px solid var(--black);
  border-right: 1px solid var(--black);
}

.footnotes-header {
  display: flex;
  justify-content: space-between;
}

.footnotes-close {
  display: none;
}

.footnotes h1 {
  font-family: "Helveesti";
  /* font-style: italic !important; */
  font-weight: 400;
  font-size: var(--footnote) !important;
  line-height: 0.5rem !important;
  color: var(--black) !important;
  margin: 0 !important;
  text-align: justify;
  border: none !important;
  border: none !important;
  background: white !important;
  padding: 0.6rem 0.5rem !important;
  display: inline-block;
  text-align: left !important;
  /* text-transform: uppercase; */
}

.footnote {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  max-height: 0;
  overflow: hidden;
  padding: 0rem;
  background: white;
  transition: all ease-in-out 300ms;
  /* transition: border-bottom ease-out 0ms; */
  border-top: 1px solid black;
  border-bottom: 0px solid var(--black);
}

.footnote.loaded {
  border-bottom: 1px solid var(--black);
  max-height: 1000px;
}

.heading {
  border-bottom: 1px solid var(--black);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.footnote img {
  width: 100%;
  border-bottom: 1px solid black;
}

.footnote-image {
  opacity: 0;
  transition: opacity ease-in-out 300ms;
}

.footnote-image.show {
  opacity: 1;
}

.footnote-number {
  vertical-align: super;
  font-size: var(--footnote2);
  line-height: 0;
  transition: all ease-in-out 250ms;
}

.footnote-number:hover {
  cursor: pointer;
  background: yellow !important;
  color: var(--black);
}

.footnote-text {
  font-family: "Helveesti";
  font-size: var(--footnote2) !important;
  color: var(--black) !important;
  display: inline !important;
  text-align: left !important;
  transition: all ease-in-out 200ms !important;
  padding: 0.5rem !important;
}

.footnote-text a {
  font-family: "Helveesti";
  font-size: var(--footnote2) !important;
  color: var(--black) !important;
  text-decoration: underline;
}

.higlighted {
  background: yellow !important;
}

.footnote-text.active {
  /* animation: highlightFootnote 10s ease-in-out forwards; */
  background: var(--black);
  color: white;
}

@keyframes highlightFootnote {
  0% {
    background-color: var(--black);
    color: white;
  }

  99% {
    background-color: var(--black);
    color: white;
  }

  100% {
    background-color: white;
    color: var(--black);
  }
}

.footnote-text a {
  color: var(--black);
  text-decoration: underline;
  transition: color ease-in-out 100ms;
}

.footnote-text a:hover {
  color: var(--blue);
  text-decoration: underline;
}

.footnote-text-link {
  font-family: "Helveesti";
  font-size: var(--footnote);
  color: var(--black);
  transition: color ease-in-out 100ms;
  padding-bottom: 4px;
  display: block;
  text-align: left;
}

.footnote-text-link:hover {
  cursor: pointer;
  color: var(--blue);
}


.logo {
  width: 120px;
}

.logo-sticky {
  position: absolute;
  width: 100%;
  top: calc(50dvh - 60px);
  text-align: center;
  z-index: 100;
  mix-blend-mode: normal;
}

.logo-fixed {
  position: fixed;
  width: 100%;
  text-align: center;
  left: 0;
  margin-top: 0;
  transform: none;
  z-index: 50;
  mix-blend-mode: overlay;
}

.main {
  position: relative;
  width: 100vw;
}

.masthead {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.masthead p {
  font-size: 0.7rem !important;
  text-align: left !important;
}

.modal {
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
}

.modal-close {
  position: absolute;
  z-index: 110;
  top: 1.75rem;
  right: 1.75rem;
  font-family: "Helveesti";
  font-size: var(--main-font);
  color: var(--black);
  transition: color ease 250ms;
}

.modal-close:hover {
  cursor: pointer;
  color: var(--black);
}

.modal-content {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content img {
  min-height: 100%;
  min-width: 100%;
}

.navbar {
  position: fixed;
  z-index: 100;
  padding: 0.75rem 1rem 0.7rem 1rem;
  top: 0;
  z-index: 50;
  background-color: white;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  gap: 1rem;
  border-bottom: 1px solid var(--black);
}

.left {
  display: inline-block;
  white-space: nowrap;
}

.right {
  display: flex;
  justify-content: right;
  gap: 0.5rem;
  width: 100%;
  background: right;
}


.navbar-item {
  font-family: "Helveesti";
  font-size: var(--header-font);
  color: var(--black);
  display: inline-block;
  width: fit-content;
}

.navbar-item a {
  font-size: var(--header-font);
  color: var(--black);
  border: 1px solid var(--black);
  text-decoration: none;
  transition: all ease-in-out 250ms;
  padding: 0 1rem;
}

.navbar-item a:hover {
  color: white;
  text-decoration: none;
  border: 1px solid var(--black);
  background: var(--black);
  transition: all ease-in-out 250ms;
}

.navbar-item a.selected {
  transition: none;
  color: var(--black);
  background: yellow;
  border-color: yellow;
}

.navbar-home {
  font-family: "Helveesti";
  font-size: var(--header-font);
  color: white;
  background: var(--black);
  display: inline-block;
  width: fit-content;

}

.navbar-home a {
  font-size: var(--header-font);
  color: white;
  border: 1px solid var(--black);
  background: var(--black);
  text-decoration: none;
  padding: 0 1rem;
  transition: all ease-in-out 250ms;
}

.navbar-home a:hover {
  color: var(--black);
  text-decoration: none;
  border: 1px solid yellow;
  background: yellow;
}

.email {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  line-height: 1rem;
  color: var(--black);
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--black);
  width: 100%;
  padding: 0px;
  display: inline-block;
  transition: color 200ms ease-in-out;
}

.email:focus {
  outline: none;
  color: var(--black);
}

.email::placeholder {
  color: var(--black);
}


.newsletter form {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.subscribe {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  /* line-height: 1.3rem; */
  padding: 0.25rem;
  color: var(--black);
  background: var(--gray);
  border: 1px solid var(--black);
  border-radius: 0;
  display: inline-block;
  transition: background 200ms ease-in-out;
}

.subscribe:focus {
  background: var(--black);
  color: white;
  cursor: pointer;
  /* mix-blend-mode: normal; */
}

.subscribe:hover {
  background: var(--black);
  color: white;
  cursor: pointer;
  /* mix-blend-mode: normal; */
}

.donate {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  /* line-height: 1.3rem; */
  padding: 0.25rem;
  color: var(--black);
  background: var(--gray);
  border: 1px solid var(--black);
  border-radius: 0;
  display: inline-block;
  transition: background 200ms ease-in-out;
  width: 100%;
}

.donate:focus {
  background: var(--black);
  color: white;
  cursor: pointer;
  /* mix-blend-mode: normal; */
}

.donate:hover {
  background: var(--black);
  color: white;
  cursor: pointer;
  /* mix-blend-mode: normal; */
}

.project img {
  width: 100%;
  margin-bottom: 1rem;
}

.project img:hover {
  cursor: pointer;
  mix-blend-mode: overlay;
}

.projects {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.projects-left {
  padding-right: 0.5rem;
}

.projects-right {
  padding-left: 0.5rem;
}

.project-title {
  text-align: center !important;
  font-family: "Helveesti";
  font-size: 1rem;
  line-height: 0.5rem !important;
  color: var(--black);
  transition: color ease-in-out 100ms;
}

.project-title a {
  text-decoration: none !important;
  color: var(--black) !important;
}

.project-title a:hover {
  text-decoration: none !important;
  color: var(--blue) !important;
}



.trip-report-section {
  position: fixed;
  display: grid;
  width: 100vw;
  grid-template-columns: 4fr 2fr;
  gap: 1.5rem;
  margin-top: 50px;
  padding-right: 1.5rem;
  height: calc(100dvh - 50px);
  z-index: 1000;
}

.section h1 {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  line-height: 1.3rem;
  color: white;
  margin-bottom: 0.75rem;
  text-align: justify;
  border: 1px solid var(--black);
  background: var(--black);
  padding: 0rem 1rem;
  display: block;
  text-align: center;
}

.section h1 a {
  text-decoration: none !important;
}


.section p {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  line-height: 1.4rem;
  color: var(--black);
  text-align: left;
  margin-bottom: 1rem;
}

.section p.italic {
  font-style: italic;
}

.section p a {
  color: var(--black);
  background: yellow;
  text-decoration: none;
  transition: all ease-in-out 250ms;
  font-size: var(--main-font);
}

.section p a:hover {
  text-decoration: none;
  background: var(--black);
  color: white;
}


.section ul {
  list-style-type: none;
  margin-bottom: 1rem;
}

.section li {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  line-height: 1.3rem;
  color: var(--black);
}

.section li a {
  color: var(--black);
  text-decoration: none;
  transition: all ease-in-out 250ms;
}

.section li a:hover {
  background: yellow;
  text-decoration: none;
}

.section-left-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.section-right {
  padding-left: 1.5rem;
}

.title {
  font-style: italic;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  overflow: hidden;
}

/* For smaller desktop windows */
@media screen and (max-width: 700px) {

  .navbar {
    position: fixed;
    z-index: 100;
    padding: 0.5rem;
    top: 0;
    z-index: 50;
    background-color: white;
    display: flex;
    justify-content: left;
    width: 100vw;
    gap: 0.33rem;
    border-bottom: 1px solid var(--black);
    width: 100vw;
  }

  .navbar-item {
    font-family: "Helveesti";
    font-size: var(--main-font);
    color: var(--black);
    display: inline-block;
  }

  .navbar-item a {
    color: var(--black);
    /* border: 1px solid var(--black); */
    border: none;
    text-decoration: none;
    transition: all ease-in-out 100ms;
    padding: 0 0.1667rem;
  }

  .navbar-item a:hover {
    border: none;
  }

  .navbar-mobile a {
    display: inline-block;
  }

  .right {
    display: flex;
    justify-content: space-between;
    gap: 0.33rem;
    width: 100%;
    background: right;
  }

  .left {
    display: none;
  }

  .footnotes-container {
    position: absolute;
    width: 100dvw;
    height: calc(100dvh - 59px);
    left: 100dvw;
    padding: 0;
    background: white;
    overflow: scroll;
    z-index: 100000;
  }

  .footnotes {
    border: none;

  }

  .footnotes-header {
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--black);
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.35rem 0;
  }

  .footnote {
    border: none !important;
    overflow: visible;
  }

  .footnote.loaded {
    min-height: 100px;
  }

  .footnote-img {
    border-top: none !important;
  }

  .footnotes h1 {
    font-size: var(--main-font) !important;
  }


  .footnote-text {
    font-size: var(--main-font) !important;
  }

  .footnote-text a {
    font-size: var(--main-font) !important;
  }

  .footnotes-close {
    display: inline-block;
    font-family: "Helveesti";
    font-weight: 400;
    font-size: var(--main-font) !important;
    line-height: 0.5rem !important;
    color: var(--black) !important;
    margin: 0 !important;
    border: none !important;
    padding: 0.6rem 0.5rem !important;
    display: inline-block;
    text-align: left !important;
    transition: all ease-in-out 250ms;
  }

  .footnotes-close:hover {
    cursor: pointer;
    color: white !important;
    background: var(--black);

  }

  .visible {
    left: 0;
  }

  .section {
    position: fixed;
    /* display: flex; */
    width: 100dvw;
    height: fit-content;
    margin-top: 41px;
    height: calc(100dvh - 41px);
    z-index: 1;
    padding: 0;
  }

  .trip-report-section {
    position: fixed;
    width: 100dvw;
    height: fit-content;
    margin-top: 41px;
    height: calc(100dvh - 41px);
    z-index: 1;
    padding: 0;
  }

  .report-overlay {
    position: fixed;
    margin-top: 43px;
    left: 0;
    width: 100%;
    height: calc(100dvh - 77px);
    background: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
    padding: 0 !important;
  }

  .report-content {
    background: white;
    border: none !important;
    width: 100% !important;
    height: calc(100dvh - 77px);
    min-width: 100% !important;
    font-family: 'Helveesti';
    position: relative;
    padding: 0;
    margin: 0;
    overflow: scroll;
  }

  form.report {
    border: none !important;
    margin: 0 !important;
    padding: 0.5rem !important;
    height: calc(100% - 38px);
    background-color: white;
  }

  .report-header {
    font-size: var(--main-font) !important;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--black);
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: 0 !important;
  }

  button.report {
    display: inline-block;
    font-family: "Helveesti";
    font-weight: 400;
    font-size: var(--main-font) !important;
    line-height: 0.5rem !important;
    color: var(--black) !important;
    margin-top: -11px !important;
    right: 0.7rem;
    border: none !important;
    padding: 0.6rem 0.5rem !important;
    display: inline-block;
    text-align: left !important;
    transition: all ease-in-out 250ms;
    background: white;
  }

  button.report:hover {
    cursor: pointer;
    color: white !important;
    background: var(--black);
  }

  .ql-editor {
    /* height: auto !important; */
    max-height: 100px !important;
  }

  input.report {
    position: relative;
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }

  input[type="date"].report {
    display: block;
    border-radius: 0;
    text-align: left;
    min-width: 100%;
    max-width: 100%;
  }

  .category {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: flex-start;
  }

  .report-response {
    padding: 0.5rem;
  }

  .copy {
    height: 100%;
    width: 100dvw;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity ease-in-out 250ms;
    padding-bottom: 50px;
    padding: 1rem 0.5rem 2.5rem 0.5rem;

  }

  .board-group {
    grid-template-columns: 1fr 1fr;
  }

  .footer {
    display: none;
  }

  .mobile-footer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    bottom: 0;
    padding: 0.8rem 0rem 0.2rem;
    overflow: hidden;
    white-space: nowrap;
    background: white;
    border-top: 1px solid var(--black);
    z-index: 50;
  }

  .mobile-footer p {
    display: none !important;
  }

  .mobile-footer-text {
    font-family: "Helveesti";
    font-size: var(--main-font) !important;
    font-style: italic !important;
    line-height: 0.5rem !important;
    color: var(--black);
    display: inline-block;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    animation: scrollText 30s linear infinite;
    margin-bottom: 0rem !important;
  }

  .mobile-footer-text a {
    color: var(--black);
    font-size: var(--main-font) !important;
    font-style: italic !important;
    text-decoration: none;
    transition: all ease-in-out 100ms;
  }


  .mobile-footer-text a:active {
    background: yellow;
    color: var(--black);
  }

  .mobile-footer-text a:visited {
    color: var(--black);
  }

}

/* For mobile */
@media screen and (max-width: 500px) {

  .report-overlay {
    position: fixed;
    margin-top: 43px;
    left: 0;
    width: 100%;
    height: calc(100dvh - 74px);
    background: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
    padding: 0 !important;
  }

  .report-content {
    border: none !important;
    width: 100% !important;
    height: calc(100dvh - 71px);
    min-width: 100% !important;
    font-family: 'Helveesti';
    position: relative;
    padding: 0;
    margin: 0;
    overflow: scroll;
  }

  .navbar {
    position: fixed;
    z-index: 100;
    padding: 0.5rem;
    top: 0;
    z-index: 50;
    background-color: white;
    display: flex;
    justify-content: left;
    width: 100vw;
    gap: 0.33rem;
    border-bottom: 1px solid var(--black);
    width: 100vw;
  }

  .navbar-item {
    font-family: "Helveesti";
    font-size: var(--main-font);
    color: var(--black);
    display: inline-block;
  }

  .navbar-item a {
    color: var(--black);
    /* border: 1px solid var(--black); */
    border: none;
    text-decoration: none;
    transition: all ease-in-out 100ms;
    padding: 0 0.1667rem;
  }

  .navbar-item a:hover {
    border: none;
  }

  .navbar-mobile a {
    display: inline-block;
  }

  .right {
    display: flex;
    justify-content: space-between;
    gap: 0.33rem;
    width: 100%;
    background: right;
  }

  .left {
    display: none;
  }

  .footnotes-container {
    position: absolute;
    width: 100dvw;
    height: calc(100dvh - 70px);
    left: 100dvw;
    padding: 0;
    background: white;
    overflow: scroll;
    z-index: 100000;
  }

  .footnotes {
    border: none;

  }

  .footnotes-header {
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--black);
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.35rem 0;
  }

  .footnote {
    border: none !important;
    overflow: visible;

  }

  .footnote.loaded {
    min-height: 100px;
  }

  .footnote-img {
    border-top: none !important;
  }

  .footnotes h1 {
    font-size: var(--main-font) !important;
  }


  .footnote-text {
    font-size: var(--main-font) !important;
  }

  .footnote-text a {
    font-size: var(--main-font) !important;
  }

  .footnotes-close {
    display: inline-block;
    font-family: "Helveesti";
    font-weight: 400;
    font-size: var(--main-font) !important;
    line-height: 0.5rem !important;
    color: var(--black) !important;
    margin: 0 !important;
    border: none !important;
    padding: 0.6rem 0.5rem !important;
    display: inline-block;
    text-align: left !important;
    transition: all ease-in-out 250ms;
  }

  .footnotes-close:hover {
    cursor: pointer;
    color: white !important;
    background: var(--black);

  }

  .visible {
    left: 0;
  }


  .section {
    position: fixed;
    /* display: flex; */
    width: 100dvw;
    height: fit-content;
    margin-top: 41px;
    height: calc(100dvh - 41px);
    z-index: 1;
    padding: 0;
  }

  .copy {
    height: 100%;
    width: 100dvw;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity ease-in-out 250ms;
    padding-bottom: 50px;
    padding: 1rem 0.5rem 2.5rem 0.5rem;

  }

  .board-group {
    grid-template-columns: 1fr 1fr;
  }

  .footer {
    display: none;
  }

  .mobile-footer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    bottom: 0;
    padding: 0.5rem 0rem 0rem;
    overflow: hidden;
    white-space: nowrap;
    background: white;
    border-top: 1px solid var(--black);
    z-index: 50;
  }

  .mobile-footer p {
    display: none !important;
  }

  .mobile-footer-text {
    font-family: "Helveesti";
    font-size: 0.5rem !important;
    font-style: italic !important;
    line-height: 0.5rem !important;
    color: var(--black);
    display: inline-block;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    animation: scrollText 30s linear infinite;
    margin-bottom: 0rem !important;
  }

  .mobile-footer-text a {
    color: var(--black);
    font-size: 0.5rem !important;
    font-style: italic !important;
    text-decoration: none;
    transition: all ease-in-out 100ms;
  }


  .mobile-footer-text a:active {
    background: yellow;
    color: var(--black);
  }

  .mobile-footer-text a:visited {
    color: var(--black);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}


@font-face {
  font-family: "Helveesti";
  src: url(../../public/assets/fonts/helveesti-regular.woff);
}

@font-face {
  font-family: "Helveesti";
  src: url(../../public/assets/fonts/helveesti-regular-italic.woff);
  font-style: italic;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 16px;
}

form.admin {
  border: 1px solid black;
  padding: 1rem;
  background: #e6e6e6;
  margin-top: 0.5rem;
}

.admin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.admin-content {
  background: white;
  border: 1px solid black;
  width: 100%;
  max-width: 800px;
  font-family: 'Helveesti';
  position: relative;
  padding: 0.5rem 1rem 1rem;
  overflow: scroll;
}

.admin-content p {
  margin-bottom: 0.125rem;
}

.highlighted {
  background: yellow;
}

.description {
  border-top: 1px solid black !important;
  padding-top: 0.5rem;

}

.admin-header {
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  line-height: 1.3rem;
  color: black;
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
}

button.admin {
  border: 1px solid black;
  background: white;
  padding: 0rem 0.5rem;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: black;
  background: var(--gray);
  text-align: center;
  transition: all ease-in-out 250ms;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

button.admin:hover {
  cursor: pointer;
  background: black;
  color: white;
}

input.accept {
  background: seagreen;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  display: inline-block;
  font-family: "Helveesti";
  width: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: white;
  transition: all ease-in-out 250ms;
  border: 1px solid black;
}

input.accept:hover {
  cursor: pointer;
  background: darkgreen;
}

input.reject:hover {
  cursor: pointer;
  background: darkred;
}

input.reject {
  background: indianred;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  display: inline-block;
  width: 50%;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: white;
  transition: opacity ease-in-out 250ms;
  border: 1px solid black;
}

.pending-controls {
  padding: 0.5rem 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  border-bottom: 1px solid;
}

input[type="text"].admin {
  background: lightgray;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 350px;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: gray;
  transition: opacity ease-in-out 250ms;
}

input[type="password"].admin {
  background: lightgray;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  display: block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 350px;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: gray;
}

input[type="submit"].admin {
  border: 1px solid black;
  background: white;
  padding: 0rem 0.5rem;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--header-font);
  color: black;
  text-align: center;
  transition: all ease-in-out 250ms;
}

input[type="submit"].admin:hover {
  cursor: pointer;
  background: black;
  color: white;
}

input.admin:focus {
  border: 1px solid black;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: black;
  /* Adds a subtle blue glow around the input */
}

textarea.report {
  background: lightgray;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  width: 100%;
  height: 100px;
  border: 1px solid lightgray;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  resize: none;
  margin-bottom: 0.5rem;
}

textarea.report:focus {
  border: 1px solid black;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

select.report {
  background: lightgray;
  border: 1px solid lightgray;
  padding: 0.5rem 0.25rem;
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 350px;
  font-family: "Helveesti";
  font-style: normal;
  font-weight: 400;
  font-size: var(--main-font);
  color: gray;
}

select.report:focus {
  border: 1px solid black;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  color: black;
  /* Adds a subtle blue glow around the input */
}

.sub-category {
  opacity: 1;
  transition: opacity ease-in 250ms;
}

.invisible {
  opacity: 0;
}

.category {
  display: flex;
  justify-content: flex-start;
}



.author p {
  font-family: 'Helveesti';
  font-size: 0.7rem;
  color: white;
  padding: 0;
  margin: 0;
  text-align: center;
}

.logo {
  width: 100%;
  height: auto;
  background-color: red;
  display: block;
}

.logo img {
  border: none;
}

.author {
  display: block;
  background-color: black;
  padding: 0;
  margin: 0;
}

.past-reports-container {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 1rem;
}

.past-reports-container>*:last-child {
  margin-bottom: 0;
}

.past-report {
  background: black;
  display: block;
  overflow: hidden;
  border: 1px solid var(--black);
}

.past-report img {
  border: none !important;
  width: 100%;
  transform: scale(1.1);
  opacity: 0.7;
  transition: all ease-in-out 250ms;
}

.past-report img:hover {
  opacity: 1;
  cursor: pointer;
}

.past-report-text {
  position: absolute;
  z-index: 100;
  bottom: -0.5rem;
  font-size: 0.7rem !important;
  line-height: 1rem !important;
  text-align: right !important;
  color: white !important;
  background: black;
  display: block;
  width: 100%;
}

.category-field {
  display: block !important;
}

.pending-report {
  border: 1px solid black;
  background: #e6e6e6;
  padding: 0.5rem;
  margin-top: 1rem;
}

.results {
  border-top: 1px solid black;
  padding-top: 1rem;
}

@media screen and (max-width: 767px) {

  input.report {
    width: 100%;
    border-radius: 0;
  }

  .category div input[type="text"].report {
    display: block;
    border-radius: 0;
    width: 100%;
  }

  input[type="email"].report {
    display: block;
    border-radius: 0;
    width: 100%;
  }

  input[type="date"].report {
    display: block;
    border-radius: 0;
    text-align: left;
    min-width: 100%;
    max-width: 100%;
  }

  textarea.report {
    border-radius: 0;
    height: 100px;
  }

  select.report {
    border-radius: 0;
    width: 100%;
    display: block !important;
  }

  .report-content {
    width: 100%;
    margin: 1rem;
  }

  .category {
    display: block;
  }

  .pending-report {
    margin: 1rem;
    background: red;
  }
}


.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin-bottom: 1rem !important;
  position: relative;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 150px !important;
  outline: none;
  overflow-y: scroll;
  padding: 0.5rem;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
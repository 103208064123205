.donation-form-container {
  position: fixed;
  display: grid;
  width: 100dvw;
  grid-template-columns: 1fr;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: auto;
}

.donation-form {
  font-family: "Helveesti" !important;
  font-size: 1.75rem !important;
  position: absolute;
  min-height: 900px;
  max-height: 1000px;
  width: 300px;
  top: calc(50% - 245px);
  left: calc(50% - 150px);
}